import { useRouterLinkContext } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { DestinationSelector } from './DestinationSelector';
import { Popover } from '../Popover/Popover';
import { PopoverOrModal } from '../Popover/PopoverOrModal';
import { useFakeAutoFocusTrigger } from '../useFakeAutoFocusTrigger';
import { AccommodationSuggestion } from '@AuroraTypes';
import { ClassNameProps } from '@ComponentProps';
import { Button } from '@Components/Button/Button';
import { LoadableModal } from '@Components/Modal/LoadableModal';
import { useModal } from '@Components/Modal/useModal';
import { useSearchResultsPageUrl } from '@Components/SearchForm/useSearchResultsPageUrl';
import { unique } from '@Core/helpers/array';
import { trackEventClick } from '@Core/tracking/hooks/useInteractionTracking';
import { usePathTemplate } from '@Core/usePathTemplate';
import { usePandaPageUrl } from '@Pages/search-results/usePandaPageUrl';
import {
  useSearchAvailabilityStore,
  useStoreContext,
  useSearchSelectionStore,
} from '@Stores/StoreContext';
import { SearchInput } from '@UX/components/Search/SearchInput/SearchInput';

export interface DestinationInputProps extends ClassNameProps {
  hasInputTitle?: boolean;
  showInputTitleOnMobile?: boolean;
  allowTagsRemoval?: boolean;
}

export const DestinationInput: React.FC<DestinationInputProps> = ({
  className,
  hasInputTitle,
  showInputTitleOnMobile,
  allowTagsRemoval,
}) => {
  const { t } = useTranslation();
  const [isOpen, setOpen, setClose] = useModal();
  const history = useHistory();
  const { searchSelection } = useStoreContext();
  const tags = useSearchAvailabilityStore((state) =>
    state.destinations.map(({ id, name }) => ({ label: name, value: id })),
  );
  const areTagsProvided = tags && tags.length;
  const [destinationIds, setDestinationIds] = useSearchSelectionStore((state) => [
    state.destinationIds,
    state.setDestinationIds,
  ]);
  const [savedDestinationIds, setSavedDestinationIds] = useState<string[]>(destinationIds);
  const getSearchResultsPageUrl = useSearchResultsPageUrl();
  const pandaPath = usePathTemplate('panda');
  const { getPandaUrlParams } = usePandaPageUrl();

  const { useHref } = useRouterLinkContext();

  const onHotelClick = useCallback((hotel: AccommodationSuggestion) => {
    const selection = searchSelection.getState();

    if (hotel && hotel.isLimitlessOnly) {
      history.push({
        pathname: pandaPath,
        search: getPandaUrlParams(hotel.id, selection),
      });
    } else if (hotel.isStaticHotel) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      window.location.href = useHref(
        getSearchResultsPageUrl({
          type: 'HOSRP',
          selection,
          masterId: hotel.id,
        }),
      );
    } else {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      window.location.href = useHref(
        getSearchResultsPageUrl({
          type: 'DPSRP',
          selection: selection.extend({
            destinationIds: unique(
              [
                ...selection.destinationIds,
                `${hotel.accommodation?.destination?.resort?.id || ''}`,
              ].filter(Boolean),
            ),
            pinnedMasterIds: [Number(hotel.id)],
          }),
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inputRef = useRef<HTMLDivElement>(null);

  const triggerFakeInputFocus = useFakeAutoFocusTrigger(inputRef);

  const onOpen = useCallback(() => {
    setOpen();
    triggerFakeInputFocus();
    trackEventClick('search-ui-destination-input');
  }, [setOpen, triggerFakeInputFocus]);

  const onCancel = useCallback(() => {
    setDestinationIds(savedDestinationIds);
    setClose();
  }, [savedDestinationIds, setClose, setDestinationIds]);

  const onRemoveTag = useCallback(
    (destinationId) => {
      setDestinationIds(destinationIds.filter((id) => id !== destinationId));
    },
    [destinationIds, setDestinationIds],
  );

  useEffect(() => {
    if (isOpen) {
      setSavedDestinationIds(destinationIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const trigger = (
    <SearchInput
      data-id="destination-input"
      ref={inputRef}
      className={className}
      placeholder={t('searchUi.destinationInput.anyDestinationOrHotel')}
      onClick={onOpen}
      showTitleOnMobile={showInputTitleOnMobile}
      tags={tags}
      removeTag={allowTagsRemoval ? onRemoveTag : undefined}
      {...(hasInputTitle && {
        title: t('facets.destinations'),
      })}
      {...(areTagsProvided && !hasInputTitle
        ? {
            prefix: t('to'),
          }
        : {
            prefixIconName: 'Content/Place',
          })}
    />
  );

  return (
    <PopoverOrModal
      isOpen={isOpen}
      popover={
        <Popover
          className={className}
          size="Medium"
          isOpen={isOpen}
          anchorPosition={hasInputTitle ? 'top-left' : 'top-left-nopadding'}
          trigger={trigger}
          onClickOutside={setClose}
          contentStyle={{
            paddingX: 'xs',
            paddingTop: 0,
            paddingBottom: 0,
          }}
        >
          <DestinationSelector
            onHotelClick={onHotelClick}
            closeModal={setClose}
            mainContentWrapperStyles={{
              maxHeight: '50vh',
              overflowY: 'auto',
              marginX: '-xs',
              paddingX: 'xs',
              paddingBottom: 'l',
            }}
          />
        </Popover>
      }
      modal={
        <Fragment>
          {trigger}
          <LoadableModal
            show={isOpen}
            data-id="modal-container"
            onClose={setClose}
            Actions={
              <Fragment>
                <Button
                  data-id="cancel-btn"
                  variant="TextSecondary"
                  size="48"
                  onClick={onCancel}
                >
                  {t('cancel')}
                </Button>
                <Button
                  data-id="done-btn"
                  variant="PrimaryDefault"
                  size="48"
                  onClick={setClose}
                >
                  {t('done')}
                </Button>
              </Fragment>
            }
            Content={
              <DestinationSelector
                onHotelClick={onHotelClick}
                closeModal={setClose}
              />
            }
          />
        </Fragment>
      }
    />
  );
};

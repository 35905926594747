import { Label, PillSelector } from '@loveholidays/design-system';
import React, { useEffect } from 'react';

import { getMonthCalendar } from './getMonthCalendar';
import { MONTH_FLEXIBILITY_VALUE } from '@Constants/Flexibility';
import { getScrollBehavior } from '@Core/scroll-helpers/getScrollBehavior';
import { useDate } from '@Dates/useDateHook';
import { useSearchAvailabilityStore, useSearchSelectionStore } from '@Stores/StoreContext';

export const MonthSelector: React.FC = () => {
  const [date, setDate, isMonthSelected] = useSearchSelectionStore((state) => [
    state.date,
    state.setDate,
    state.isMonthSelected(),
  ]);

  const calendar = useSearchAvailabilityStore((state) => state.calendar);
  const { formatDate, utcDate } = useDate();

  const yearGroups = getMonthCalendar(calendar);

  useEffect(() => {
    if (!isMonthSelected || !date) {
      return;
    }

    const el = document.querySelector(`[data-id=year-${date.format('YYYY')}]`);

    if (el) {
      setTimeout(() => {
        el.scrollIntoView({
          behavior: getScrollBehavior(),
          block: 'nearest',
          inline: 'start',
        });
      }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section
      sx={{
        overflowY: [null, 'auto'],
        height: [null, '415px'],
        marginX: [null, '-l'],
        paddingX: [null, 'l'],
      }}
    >
      {Object.keys(yearGroups).map((year) => (
        <div
          key={year}
          data-id={`year-${year}`}
          sx={{
            marginBottom: '2xs',
          }}
        >
          <Label
            variant="largebold"
            sx={{
              textAlign: 'center',
              display: 'block',
              paddingY: 's',
            }}
          >
            {year}
          </Label>

          <div
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(4, 1fr)',
              columnGap: 16,
              rowGap: 16,
            }}
          >
            {Object.entries(yearGroups[year]).map(([yearMonth, available]) => {
              const disabled = !available;
              const selected =
                date && date.isSame(utcDate(yearMonth, 'YYYY-MM')) && isMonthSelected;

              return (
                <PillSelector
                  disabled={disabled}
                  key={yearMonth}
                  selected={selected}
                  onTrigger={() => {
                    setDate(utcDate(yearMonth, 'YYYY-MM'), MONTH_FLEXIBILITY_VALUE);
                  }}
                  label={formatDate(yearMonth, 'YYYY-MM', 'MMM')}
                />
              );
            })}
          </div>
        </div>
      ))}
    </section>
  );
};
